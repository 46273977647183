import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDetails } from 'use-places-autocomplete';
import { updateMapPin } from '../../redux/slices/Map';
/**
 * PlacesImage component
 *
 * This component is responsible for displaying an image of a place. It fetches the image URL from the map pin data
 * and updates the map pin with the new image URL (fetched from Google Places API) if the image fails to load.
 *
 * Props:
 * - tripId: The ID of the trip (optional).
 * - mapPin: The ID of the map pin.
 * - fallbackImage: The fallback image URL to be used if the image fails to load. Default is '/images/placeholderImage.png'.
 * - render: A function to render the image. If provided, the image will be rendered using the function.
 * - ...props: Any other props to be passed to the img element.
 *
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */

function PlacesImage({
  tripId: _tripId,
  mapPin,
  fallbackImage = '/images/placeholderImage.png',
  render,
  ...props
}) {
  const [imageUrl, setImageUrl] = useState(fallbackImage);
  const mapPins = useSelector((state) => state.Map.mapPins);
  const [tripId, setTripId] = useState(_tripId);
  const dispatch = useDispatch();

  if (!tripId) {
    const localTripId = Object.keys(mapPins).find((key) => {
      return Object.keys(mapPins[key]).includes(mapPin);
    });
    setTripId(localTripId);
  }

  /**
   * Handles the image error by fetching a new image URL from the place details and updating the map pin.
   *
   * @returns {Promise<string>} The new image URL.
   */
  const handleImageError = async () => {
    const pin = mapPins[tripId][mapPin];

    const details = await getDetails({
      placeId: pin?.placeId,
      fields: ['photos'],
    });
    const photo = details?.photos[0]?.getUrl();
    setImageUrl(photo);
    const pindata = JSON.parse(pin?.pinData || '{}');
    dispatch(
      updateMapPin({
        context: {
          tripId,
        },
        variables: {
          ...pin,
          pinData: JSON.stringify({
            ...pindata,
            photo,
          }),
        },
      })
    );
    return photo;
  };

  useEffect(() => {
    if (mapPins[tripId]) {
      const pin = JSON.parse(mapPins[tripId][mapPin].pinData);
      setImageUrl(pin.photo);
      const image = new Image();
      image.src = pin.photo;
      image.onload = () => {
        if (image.height === 100 && image.width === 100) {
          handleImageError();
          setImageUrl(fallbackImage);
        }
      };
    }
  }, [tripId]);

  if (render) {
    return render({ imageUrl });
  }
  if (!imageUrl) {
    return <img src={fallbackImage} alt={props.alt || 'place'} {...props} />;
  }
  return <img src={imageUrl} alt={props.alt || 'place'} {...props} />;
}

export default PlacesImage;
